import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { EmptyCircle, FilledCircle } from "styled/common/Buttons";
import {
  CenterFlex,
  InvertColors,
  NonSelectable,
  SizeFillSpaceMixin,
  StaticElement
} from "styled/common/Utils";
import styled, { css } from "styled-components";

export type DirectionalButtonVariant =
  | "carousel-arrow"
  | "carousel-arrow-back"
  | "home-projects-left-button"
  | "home-projects-right-button"
  | "locomotive-carousel-arrow"
  | "locomotive-carousel-arrow-back";

const CarouselArrow = css`
  ${SizeFillSpaceMixin}
  ${StaticElement}
`;
const CarouselArrowBack = css`
  ${CarouselArrow}
  transform-origin: center;
  transform: rotate(180deg);
`;

export const DirectionalButtonArrow = styled.img`
  ${CalculateFromWidth("width", { mobile: 4.5, tablet: 3.2, desktop: 1.6 })}
  z-index: 2;
  margin: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  ${InvertColors}
  ${NonSelectable}
`;

const HomeProjectsLeftButton = css`
  cursor: pointer;
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 1 / span 2;
    grid-row: 2 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 1 / span 2;
    grid-row: 2 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 1 / span 3;
    grid-row: 1 / span 3;
  }
  ${CalculateFromHeight("margin-top", { mobile: 15, tablet: -9, desktop: 27 })}
  ${CalculateFromWidth("padding-left", { mobile: 6, tablet: 6, desktop: 12 })}
  ${DirectionalButtonArrow} {
    transform: scaleX(-1);
  }
  &:disabled {
    cursor: context-menu;
  }
`;

export const DirectionalButtonWrapper = styled.div`
  ${CalculateFromWidth("width", { mobile: 14, tablet: 9, desktop: 4.4 })}
  ${CalculateFromWidth("height", { mobile: 14, tablet: 9, desktop: 4.4 })}
  ${CenterFlex}
  z-index: 4;
  position: absolute;
`;

const HomeProjectsRightButton = css`
  cursor: pointer;
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-column: 11 / span 2;
    grid-row: 2 / span 2;
    ${DirectionalButtonWrapper} {
      margin-left: auto;
    }
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-column: 11 / span 2;
    grid-row: 2 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-column: 10 / span 3;
    grid-row: 1 / span 3;
  }
  ${CalculateFromHeight("margin-top", { mobile: 15, tablet: -9, desktop: 27 })}
  ${CalculateFromWidth("padding-left", { mobile: 7, tablet: 2 })}
  ${CalculateFromWidth("padding-right", { mobile: 3, desktop: 14 })}
  &:disabled {
    cursor: context-menu;
  }
`;

const LocomotiveCarouselArrowMixin = css`
  ${CalculateFromWidth("width", { mobile: 14, tablet: 9, desktop: 4.4 })}
  ${CalculateFromWidth("height", { mobile: 14, tablet: 9, desktop: 4.4 })}
  position: absolute;
  top: 50%;
  z-index: 5;
`;

const LocomotiveCarouselArrow = css`
  ${LocomotiveCarouselArrowMixin}
  ${CalculateFromWidth("right", { mobile: 6, tablet: 6, desktop: 3 })}
  transform: translateY(-50%);
`;
const LocomotiveCarouselArrowBack = css`
  ${LocomotiveCarouselArrowMixin}
  ${CalculateFromWidth("left", { mobile: 6, tablet: 6, desktop: 3 })}
  transform: translateY(-50%) rotate(180deg);
`;

export const ButtonDirectional = styled.button<{ variant: DirectionalButtonVariant }>`
  ${({ variant }) => variant === "carousel-arrow" && CarouselArrow}
  ${({ variant }) => variant === "carousel-arrow-back" && CarouselArrowBack}
  ${({ variant }) => variant === "locomotive-carousel-arrow" && LocomotiveCarouselArrow}
  ${({ variant }) => variant === "locomotive-carousel-arrow-back" && LocomotiveCarouselArrowBack}
  ${({ variant }) => variant === "home-projects-left-button" && HomeProjectsLeftButton}
  ${({ variant }) => variant === "home-projects-right-button" && HomeProjectsRightButton}
`;

export const DirectionalButtonEmptyCircle = styled.div`
  ${EmptyCircle}
  ${CenterFlex}
`;

export const DirectionalButtonFilledCircle = styled.div`
  ${FilledCircle}
  background-color: ${({ theme: { colors } }) => colors.white};
`;
