import React, { useRef } from "react";

import LocomotiveElement from "components/locomotive/LocomotiveElement/LocomotiveElement";
import {
  AboutSquadDescription,
  SquadDescriptionContent
} from "components/pages/about/AboutSquadDescription/AboutSquadDescription.styled";
import { registerScrollAnimation, useScrollContext } from "contexts/ScrollContext";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import Translate from "utils/translation/translation";

export const SquadDescription = () => {
  const squadDescription = useRef<HTMLParagraphElement>(null);
  const [, dispatch] = useScrollContext();

  useFirstRenderEffect(() => {
    dispatch(
      registerScrollAnimation({
        target: squadDescription.current as HTMLElement,
        animation: { opacity: 0, duration: 1 },
        triggerOffsetMultiplier: -0.5
      })
    );
  });

  return (
    <AboutSquadDescription>
      <LocomotiveElement variant="AboutSquadDescriptionHeader" scrollSpeed={0.5} scrollDelay={0.5}>
        <SquadDescriptionContent ref={squadDescription}>
          {Translate("About_Long_Team_Description")}
        </SquadDescriptionContent>
      </LocomotiveElement>
    </AboutSquadDescription>
  );
};

export default SquadDescription;
