import React, { useRef } from "react";

import HeaderGroup, { HeaderGroupProps } from "components/common/headers/HeaderGroup/HeaderGroup";
import IconContainer from "components/common/other/IconContainer/IconContainer";
import { AboutOurValues } from "components/pages/about/AboutOurValues/AboutOurValues.styled";
import { registerScrollAnimation, useScrollContext } from "contexts/ScrollContext";
import { Circ } from "gsap";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";
import Translate from "utils/translation/translation";

export const OurValues = () => {
  const qualityWrapper = useRef<HTMLDivElement>(null);
  const passionWrapper = useRef<HTMLDivElement>(null);
  const commitmentWrapper = useRef<HTMLDivElement>(null);
  const atmosphereWrapper = useRef<HTMLDivElement>(null);
  const developmentWrapper = useRef<HTMLDivElement>(null);
  const [, dispatch] = useScrollContext();

  const headerData: HeaderGroupProps = {
    instantPlayback: false,
    animationPlaybackOffset: -0.75,
    headers: [
      {
        text: "About_Header_Our",
        variant: "AboutOurValuesTitleOur",
        id: "about-our",
        animation: { x: "-20%", opacity: 0, duration: 0.75, delay: 0, ease: Circ.easeOut },
        horizontalScrollSpeed: 0.75
      },
      {
        text: "About_Header_Values_Values",
        variant: "AboutOurValuesTitleValues",
        id: "about-values",
        animation: { x: "20%", opacity: 0, duration: 0.75, delay: 0.125, ease: Circ.easeOut },
        horizontalScrollSpeed: 0.75
      }
    ]
  };

  useFirstRenderEffect(() => {
    dispatch(
      registerScrollAnimation({
        target: qualityWrapper.current as HTMLElement,
        animation: { opacity: 0, y: 100, duration: 1, ease: Circ.easeOut },
        triggerOffsetMultiplier: -0.5
      })
    );
    dispatch(
      registerScrollAnimation({
        target: commitmentWrapper.current as HTMLElement,
        animation: { opacity: 0, y: 100, duration: 1, ease: Circ.easeOut },
        triggerOffsetMultiplier: -0.5
      })
    );
    dispatch(
      registerScrollAnimation({
        target: passionWrapper.current as HTMLElement,
        animation: { opacity: 0, y: 100, duration: 1, ease: Circ.easeOut },
        triggerOffsetMultiplier: -0.5
      })
    );
    dispatch(
      registerScrollAnimation({
        target: atmosphereWrapper.current as HTMLElement,
        animation: { opacity: 0, y: 100, duration: 1, ease: Circ.easeOut },
        triggerOffsetMultiplier: -0.5
      })
    );
    dispatch(
      registerScrollAnimation({
        target: developmentWrapper.current as HTMLElement,
        animation: { opacity: 0, y: 100, duration: 1, ease: Circ.easeOut },
        triggerOffsetMultiplier: -0.5
      })
    );
  });

  return (
    <AboutOurValues>
      <HeaderGroup {...headerData} />
      <IconContainer
        number=""
        elementRef={qualityWrapper}
        aboutValue="quality"
        title={Translate("About_Header_Quality")}
        iconName={"QualityIcon"}
        description={Translate("About_Text_Quality")}
      />
      <IconContainer
        number=""
        elementRef={commitmentWrapper}
        aboutValue="commitment"
        title={Translate("About_Header_Commitment")}
        iconName={"ExperienceIcon"}
        description={Translate("About_Text_Commitment")}
      />
      <IconContainer
        number=""
        elementRef={passionWrapper}
        aboutValue="passion"
        title={Translate("About_Header_Passion")}
        iconName={"PassionIcon"}
        description={Translate("About_Text_Passion")}
      />
      <IconContainer
        number=""
        elementRef={atmosphereWrapper}
        aboutValue="atmosphere"
        title={Translate("About_Header_Atmosphere")}
        iconName={"AtmosphereIcon"}
        description={Translate("About_Text_Atmosphere")}
      />
      <IconContainer
        number=""
        elementRef={developmentWrapper}
        aboutValue="development"
        title={Translate("About_Header_Development")}
        iconName={"InnovationIcon"}
        description={Translate("About_Text_Development")}
      />
    </AboutOurValues>
  );
};

export default OurValues;
