import React from "react";

import PageTitle, { type PageTitleProps } from "components/common/other/PageTitle/PageTitle";
import Recommendations from "components/common/recommendations/Recommendations/Recommendations";
import Newsletter from "components/common/sections/Newsletter/Newsletter";
import Services from "components/common/sections/Services/Services";
import FloatingImages from "components/images/ImagesFloating/ImagesFloating";
import SquareImage from "components/images/ImageSquare/ImageSquare";
import Layout from "components/layout/Layout/Layout";
import OurValues from "components/pages/about/AboutOurValues/AboutOurValues";
import SquadDescription from "components/pages/about/AboutSquadDescription/AboutSquadDescription";
import Statistics from "components/pages/about/AboutStatistics/AboutStatistics";
import Photos from "components/pages/about/AboutTeamPhotos/AboutTeamPhotos";
import MainCloud from "components/rendering/MainCloud/MainCloud";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import type { PageProps } from "pages/index";
import { GetSeoData } from "utils/seo-utils";

const About = ({ location }: PageProps) => {
  return (
    <>
      <GatsbySeo {...GetSeoData("About")} />
      <Layout location={location}>
        <MainCloud color="BluePink" variant="MainCloudWrapper" scrollSpeed={-2} />
        <PageTitle {...pageTitleData} />
        <Statistics />
        <SquadDescription />
        <SquareImage
          title="About_Header_DesigningNewSolutions"
          description="About_Text_DesigningNewSolutions"
          imageName="Square1"
        />
        <Services inAbout />
        <Recommendations inAbout />
        <SquareImage
          title="About_Header_RemarkableProposals"
          description="About_Text_RemarkableProposals"
          imageName="Square2"
          left
        />
        <OurValues />
        <FloatingImages
          leftImage={"Floating4"}
          middleImage={"Floating5"}
          rightImage={"Floating6"}
          inAbout
        />
        <Newsletter cloudVariant="NewsletterCloudAbout" />
      </Layout>
    </>
  );
};

export default About;

const pageTitleData: PageTitleProps = {
  instantPlayback: true,
  variant: "about-title",
  isTitle: true,
  headers: [
    {
      text: "About_Header_Meet",
      variant: "AboutMeet",
      id: "about-meet",
      animation: { x: -100, opacity: 0, duration: 0.75, delay: 0 },
      scrollSpeed: 1
    },
    {
      text: "About_Header_Our",
      variant: "AboutOur",
      id: "about-our",
      animation: { x: 100, opacity: 0, duration: 0.75, delay: 0.75 },
      scrollSpeed: 1
    },
    {
      text: "About_Header_Team",
      variant: "AboutTeam",
      id: "about-team",
      animation: { x: -50, opacity: 0, duration: 0.75, delay: 1.5 },
      scrollSpeed: 1
    }
  ],
  subtitle: {
    text: "About_Header_Subtitle",
    variant: "about-subtitle"
  }
};
