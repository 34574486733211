import { CalculateFromHeight } from "styled/base/SizeFunctions";
import { CommonDescriptionFont } from "styled/common/font-styles/CommonFonts";
import styled from "styled-components";

export const AboutSquadDescription = styled.section`
  ${CalculateFromHeight("height", { mobile: 140, tablet: 40, desktop: 80 })}
  vertical-align: middle;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  align-items: center;
  margin-bottom: 100px;
`;

export const SquadDescriptionContent = styled.p`
  ${CommonDescriptionFont}
`;
