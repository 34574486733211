import React, { ComponentPropsWithoutRef, FC } from "react";

import Statistic from "components/pages/about/AboutStatistics/AboutStatistic";
import { AboutStatistics } from "components/pages/about/AboutStatistics/AboutStatistics.styled";
import Translate from "utils/translation/translation";

export const Statistics: FC<ComponentPropsWithoutRef<"div">> = ({ ...props }) => {
  return (
    <AboutStatistics {...props}>
      <Statistic
        variant="hours"
        duration={5}
        delay={2}
        startValue={0}
        endValue={134}
        suffix="K"
        statisticDescription={Translate("About_Statistic_Hours")}
      />
      <Statistic
        variant="code"
        duration={5}
        delay={2}
        startValue={0}
        endValue={42191}
        statisticDescription={Translate("About_Statistic_GitCommits")}
      />
      <Statistic
        variant="team-members"
        duration={5}
        delay={2}
        startValue={0}
        endValue={60}
        statisticDescription={Translate("About_Statistic_TeamMembers")}
      />
      <Statistic
        variant="team-projects"
        duration={5}
        delay={2}
        startValue={0}
        endValue={28}
        statisticDescription={Translate("About_Statistic_Projects")}
      />
    </AboutStatistics>
  );
};

export default Statistics;
