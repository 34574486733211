import React, { MouseEvent, RefObject, useRef } from "react";

import {
  ButtonDirectional,
  DirectionalButtonArrow,
  DirectionalButtonEmptyCircle,
  DirectionalButtonFilledCircle,
  type DirectionalButtonVariant,
  DirectionalButtonWrapper
} from "components/common/buttons/ButtonDirectional/ButtonDirectional.styled";
import gsap, { Power3 } from "gsap/gsap-core";
import arrow from "media/images/other/arrow.svg";
import { ifDesktopCallback, ifMobileCallback } from "utils/window-utils";

interface DirectionalButtonProps {
  callback?: (event: MouseEvent<HTMLDivElement, MouseEvent>) => void;
  variant: DirectionalButtonVariant;
  type?: string;
  buttonRef?: RefObject<HTMLDivElement | HTMLButtonElement>;
  infoDisabled?: boolean;
  onTrackedClick?: () => void;
  onTrackedHover?: () => void;
}

export const DirectionalButton = ({
  callback,
  variant,
  type = "button",
  buttonRef,
  infoDisabled,
  onTrackedClick,
  onTrackedHover
}: DirectionalButtonProps) => {
  const emptyCircle = useRef<HTMLDivElement>(null);
  const buttonArrow = useRef<HTMLImageElement>(null);
  const filledCircle = useRef<HTMLDivElement>(null);
  const isButton = type === "button";
  const isDiv = type === "div";

  const ActivateButton = () => {
    gsap.to(emptyCircle.current, { scale: 0, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(filledCircle.current, { scale: 0.85, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(buttonArrow.current, {
      filter: "invert(0)",
      scale: 0.7,
      delay: 0.1875,
      duration: 0.125
    });
    onTrackedHover && onTrackedHover();
  };

  const DeactivateButton = () => {
    gsap.to(emptyCircle.current, { scale: 1, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(filledCircle.current, { scale: 0, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(buttonArrow.current, { filter: "invert(1)", scale: 1, delay: 0.1875, duration: 0.125 });
  };

  const ClickButton = () => {
    gsap.to(filledCircle.current, { scale: 0.85, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(emptyCircle.current, { scale: 0, ease: Power3.easeInOut, duration: 0.5 });
    gsap.to(buttonArrow.current, { filter: "invert(0)", scale: 0.7, duration: 0.125 });
    gsap.to(filledCircle.current, { scale: 0, ease: Power3.easeInOut, delay: 0.5, duration: 0.5 });
    gsap.to(emptyCircle.current, { scale: 1, ease: Power3.easeInOut, delay: 0.5, duration: 0.5 });
    gsap.to(buttonArrow.current, { filter: "invert(1)", scale: 1, delay: 0.5, duration: 0.125 });
  };

  const InvokeCallback = (event: MouseEvent<HTMLDivElement, MouseEvent>) => {
    callback && callback(event);
    onTrackedClick && onTrackedClick();
  };

  return (
    <>
      {isButton && (
        <ButtonDirectional
          tabIndex={-1}
          onMouseLeave={ifDesktopCallback(DeactivateButton)}
          onMouseEnter={ifDesktopCallback(ActivateButton)}
          onTouchStart={ifMobileCallback(ActivateButton)}
          onTouchEnd={ifMobileCallback(ClickButton)}
          // Fixes a bug where the animation of the button doesn't reset when user is on tablet or mobile
          onMouseDown={ifMobileCallback(ClickButton)}
          variant={variant}
          onClick={InvokeCallback as any}
          disabled={infoDisabled}
          ref={buttonRef as RefObject<HTMLButtonElement>}
        >
          <DirectionalButtonWrapper>
            <DirectionalButtonEmptyCircle ref={emptyCircle} />
            <DirectionalButtonFilledCircle ref={filledCircle} style={{ transform: "scale(0,0)" }} />
            <DirectionalButtonArrow ref={buttonArrow} src={arrow} alt="" onClick={ClickButton} />
          </DirectionalButtonWrapper>
        </ButtonDirectional>
      )}
      {isDiv && (
        <ButtonDirectional
          as="div"
          onMouseDown={ifMobileCallback(ClickButton)}
          onTouchStart={ifMobileCallback(ActivateButton)}
          onTouchEnd={ifMobileCallback(ClickButton)}
          onMouseLeave={ifDesktopCallback(DeactivateButton)}
          onMouseEnter={ifDesktopCallback(ActivateButton)}
          variant={variant}
          onClick={InvokeCallback as any}
          ref={buttonRef as RefObject<HTMLDivElement>}
        >
          <DirectionalButtonWrapper>
            <DirectionalButtonEmptyCircle ref={emptyCircle} />
            <DirectionalButtonFilledCircle ref={filledCircle} style={{ transform: "scale(0,0)" }} />
            <DirectionalButtonArrow ref={buttonArrow} src={arrow} alt="" />
          </DirectionalButtonWrapper>
        </ButtonDirectional>
      )}
    </>
  );
};

export default DirectionalButton;
