import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import { StatisticDescriptionFont, StatisticValueFont } from "styled/common/font-styles/AboutFonts";
import styled, { css } from "styled-components";

export type AboutStatisticVariant = "hours" | "code" | "team-members" | "team-projects";

export const AboutStatistics = styled.section`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    margin-left: auto;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    margin-left: auto;
  }

  ${CalculateFromWidth("width", { mobile: 90, tablet: 80, desktop: 80 })}
  ${CalculateFromWidth("margin-left", { mobile: 5 })}
  ${CalculateFromHeight("height", { mobile: 90, tablet: 50, desktop: 15 })}
  ${CalculateFromHeight("margin-top", { mobile: 30, tablet: 15, desktop: 35 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 5, tablet: 5, desktop: 10 })}

  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, 1fr);
`;

const AboutStatisticHours = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 1 / span 2;
    grid-column: 1 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    grid-row: 1 / span 1;
    grid-column: 1 / span 6;
  }
`;

const AboutStatisticCode = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 1 / span 2;
    grid-column: 4 / span 3;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    grid-row: 1 / span 1;
    grid-column: 7 / span 5;
  }
`;

const AboutStatisticTeamMembers = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 1 / span 2;
    grid-column: 8 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    grid-row: 2 / span 1;
    grid-column: 1 / span 5;
  }
`;

const AboutStatisticTeamProjects = css`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-row: 1 / span 2;
    grid-column: 11 / span 2;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tabletMobile} {
    grid-row: 2 / span 1;
    grid-column: 7 / span 5;
  }
`;

export const AboutStatistic = styled.div<{ variant: AboutStatisticVariant }>`
  ${({ variant }) => variant === "hours" && AboutStatisticHours}
  ${({ variant }) => variant === "code" && AboutStatisticCode}
  ${({ variant }) => variant === "team-members" && AboutStatisticTeamMembers}
  ${({ variant }) => variant === "team-projects" && AboutStatisticTeamProjects}
`;

export const AboutStatisticWrapper = styled.div`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
  }
`;

export const StatisticValue = styled.p`
  ${StatisticValueFont}
  float: left;
  ${CalculateFromWidth("width", { mobile: 16, tablet: 16, desktop: 16 })}
`;

export const AboutStatisticDescription = styled.p`
  ${StatisticDescriptionFont}
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    width: 60%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    width: 50%;
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    width: 60%;
  }
  ${CalculateFromHeight("margin-top", { mobile: 2, tablet: 2, desktop: 2.5 })}
`;
