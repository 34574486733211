import {
  IconContainerDescription,
  IconContainerId,
  IconContainerImage,
  IconContainerTitle,
  IconContainerTitleWrapper,
  IconContainerWrapper
} from "components/common/other/IconContainer/IconContainer.styled";
import { CalculateFromHeight, CalculateFromWidth } from "styled/base/SizeFunctions";
import styled from "styled-components";

export const AboutOurValues = styled.section`
  ${({ theme: { breakpoints } }) => breakpoints.desktop} {
    grid-template-rows: repeat(15, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.tablet} {
    grid-template-rows: repeat(17, 1fr);
  }
  ${({ theme: { breakpoints } }) => breakpoints.mobile} {
    grid-template-rows: repeat(23, 1fr);
  }
  ${CalculateFromHeight("height", { mobile: 575, tablet: 310, desktop: 220 })}
  ${CalculateFromHeight("margin-top", { mobile: 20, tablet: 10, desktop: 10 })}
  ${CalculateFromHeight("margin-bottom", { mobile: 15, tablet: 15, desktop: 15 })}
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  ${IconContainerWrapper} {
    width: 100%;
  }

  ${IconContainerId} {
    display: none;
  }

  ${IconContainerImage} {
    margin-left: 0;
    ${CalculateFromWidth("width", { mobile: 15, tablet: 15, desktop: 7 })}
    ${CalculateFromWidth("height", { mobile: 15, tablet: 15, desktop: 7 })}
  }

  ${IconContainerDescription} {
    width: 100%;
    margin-left: 0;
  }
  ${IconContainerTitleWrapper} {
    ${CalculateFromWidth("margin-left", { mobile: -0.5, tablet: -0.5, desktop: -0.5 })}
    ${CalculateFromHeight("margin-bottom", { mobile: 7, tablet: 2, desktop: 2 })}
    ${CalculateFromHeight("margin-top", { mobile: 10 })}
  }
  ${IconContainerTitle} {
    margin-left: 0;
  }
`;
