import { CalculateFontSize } from "styled/base/SizeFunctions";
import { css } from "styled-components";

export const StatisticValueFont = css`
  ${CalculateFontSize("font-size", { mobile: 4, tablet: 6, desktop: 6 })}
  ${CalculateFontSize("line-height", { mobile: 5, tablet: 5, desktop: 5 })}
  ${({ theme: { fonts } }) => fonts.medium}
  font-weight: 300;
`;

export const StatisticDescriptionFont = css`
  ${({ theme: { fonts } }) => fonts.light}

  ${CalculateFontSize("font-size", { mobile: 1.75, tablet: 1.5, desktop: 1.5 })}
  ${CalculateFontSize("line-height", { mobile: 2.25, tablet: 2, desktop: 2 })}
`;

export const TeamPhotoNameFont = css`
  ${CalculateFontSize("font-size", { mobile: 2, tablet: 1.52, desktop: 1.52 })}
  ${CalculateFontSize("line-height", { mobile: 3, tablet: 1, desktop: 1 })}
  ${({ theme: { fonts } }) => fonts.light}
  font-weight: 300;
`;

export const TeamPhotoJobFont = css`
  ${CalculateFontSize("font-size", { mobile: 1.3, tablet: 0.9, desktop: 0.9 })}
  ${CalculateFontSize("line-height", { mobile: 2, tablet: 1.8, desktop: 1.8 })}
  ${({ theme: { fonts } }) => fonts.light}
  font-weight: 300;
  word-break: keep-all;
  white-space: nowrap;
`;
