import React, { useRef } from "react";
import CountUp from "react-countup";

import {
  AboutStatistic,
  AboutStatisticDescription,
  type AboutStatisticVariant,
  AboutStatisticWrapper,
  StatisticValue
} from "components/pages/about/AboutStatistics/AboutStatistics.styled";
import { registerScrollAnimation, useScrollContext } from "contexts/ScrollContext";
import { useFirstRenderEffect } from "hooks/useFirstRenderEffect";

interface StatisticProps {
  variant: AboutStatisticVariant;
  startValue: number;
  endValue: number;
  suffix?: string;
  duration: number;
  delay: number;
  statisticDescription: string;
}

export const Statistic = ({
  variant,
  startValue,
  endValue,
  suffix,
  duration,
  delay,
  statisticDescription
}: StatisticProps) => {
  const value = useRef<HTMLParagraphElement>(null);
  const description = useRef<HTMLParagraphElement>(null);
  const [, dispatch] = useScrollContext();

  useFirstRenderEffect(() => {
    dispatch(
      registerScrollAnimation({
        target: value.current as HTMLElement,
        animation: { opacity: 0, duration: 1 },
        triggerOffsetMultiplier: -1
      })
    );
    dispatch(
      registerScrollAnimation({
        target: description.current as HTMLElement,
        animation: { opacity: 0, duration: 1 },
        triggerOffsetMultiplier: -1
      })
    );
  });

  return (
    <AboutStatistic variant={variant}>
      <AboutStatisticWrapper>
        <StatisticValue ref={value}>
          <CountUp
            delay={delay}
            start={startValue}
            end={endValue}
            suffix={suffix ?? ""}
            duration={duration ?? 5}
          />
        </StatisticValue>
        <AboutStatisticDescription ref={description}>
          {statisticDescription}
        </AboutStatisticDescription>
      </AboutStatisticWrapper>
    </AboutStatistic>
  );
};

export default Statistic;
